import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getDataPromise, postDataPromise } from 'brock-fetch-utils';
import {
    getPassengerIdentificationUri,
    getPassengersUri,
    getSettingsUri,
} from '../constants/apiConstants';

export function buildSettingsQuery() {
    return {
        queryKey: ['settings'],
        queryFn: () => getDataPromise(getSettingsUri()),
        enabled: true,
        staleTime: Infinity,
    };
}

export function useSettings() {
    const { data: settings, ...payload } = useQuery(buildSettingsQuery());

    return { ...settings, ...payload };
}

export function fetchPassengers(id, pnr, surname) {
    return postDataPromise(getPassengersUri(), {
        encryptedRequest: id,
        pnr,
        surname,
    });
}

export function buildPassengersQuery(isEncryptedRequestRequired, parameters) {
    const keys = [];
    const { id, pnr, surname } = parameters;
    if (isEncryptedRequestRequired) {
        keys.push(id);
    } else {
        keys.push(pnr, surname);
    }

    const keysPresent = keys.every((key) => !!key);

    return {
        queryKey: ['passengers', ...keys],
        queryFn: () => fetchPassengers(id, pnr, surname),
        enabled: keysPresent,
        initialData: [],
    };
}

export function usePassengers() {
    const [searchParams] = useSearchParams();
    const { isEncryptedRequestRequired } = useSettings();

    const id = searchParams.get('id');
    const pnr = searchParams.get('pnr');
    const surname = searchParams.get('surname');

    const { data: passengers, ...payload } = useQuery(
        buildPassengersQuery(isEncryptedRequestRequired, { id, pnr, surname })
    );

    return { passengers, ...payload };
}

export function buildIdentificationQuery(pnr, surname, onSuccess) {
    return {
        queryKey: ['identification', pnr, surname],
        queryFn: () =>
            getDataPromise(getPassengerIdentificationUri({ pnr, surname })),
        enabled: false,
        onSuccess,
    };
}
